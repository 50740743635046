/* JS */

var audio = {
  success: new Audio('https://thebackoffice.github.io/audio-fx/success.wav'),
  information: new Audio('https://thebackoffice.github.io/audio-fx/info.wav'),
  error: new Audio('https://thebackoffice.github.io/audio-fx/error.wav'),
  delete: new Audio('https://thebackoffice.github.io/audio-fx/delete.wav')
};
audio.trash = audio.delete;

$(document).ready(function () {
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $('[data-ajax]').each(function () {

    var el = $(this);
    var method = el.data('ajax');

    el.click(function () {

      $.ajax({
        url: el.attr('href'),
        method: method,
        success: function (data) {
          var cmd = el.data('success');
          eval(cmd);
        },
        error: function (data) {
        }
      });
    }.bind(el));


  });

  google.charts.load('current', {'packages': ['sankey', 'treemap']});
  google.charts.setOnLoadCallback(function () {
    $('.sankey').each(function (index) {
      drawSankey(this)
    });

    $('.lego').each(function (index) {
      drawLego(this);
    });

  });

  // jquery ui version
  // $('.datepicker').datepicker({
  //   dateFormat: 'yy-mm-dd'
  // });
  // better version http://eonasdan.github.io/bootstrap-datetimepicker/
  $('.dpicker').datetimepicker({
    "format": "YYYY-MM-DD"
  });
  $('.dtpicker').datetimepicker({
    "format": "YYYY-MM-DD hh:mm A"
  });
  $('.tpicker').datetimepicker({
    format: 'LT'
  });

  //ckeditor
  $('.fuqu-ckeditor').each(function (i, el) {

    var toolbar = (typeof $(el).data('toolbar') == 'undefined') ? 'Standard' : ckeditor_toolbars[$(el).data('toolbar')];
    var height = (typeof $(el).data('height') == 'undefined') ? 600 : $(el).data('height');

    CKEDITOR.replace(el, {height: height, toolbar: toolbar});
  })

// chained menus https://github.com/tuupola/jquery_chained
  var chained = $('[data-menu-chain-parents-csv]');
  for(var i = 0; i < chained.length; i ++){
    var el = $(chained[i]);
    var parents_csv = el.data('menuChainParentsCsv');
    var url = el.data('menuChainOptionsUrl');

    el.remoteChained({
      parents: parents_csv,
      url: url,
      loading: 'loading...'
    });

    var parents = parents_csv.split(",");
    for(var j = 0; j < parents.length; j ++){
      inputChanged($(parents[j].trim()));
    }
  }

// daterange-picker
  $('.bootstrap-daterangepicker').daterangepicker({
    "autoApply": true,
    //"startDate": moment().startOf('month'),
    //"endDate": moment().endOf('month'),
    "ranges": {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'Last 365 Days': [moment().subtract(364, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
    },
    "locale": {
      "format": "YYYY-MM-DD",
      "separator": " to ",
      "applyLabel": "Apply",
      "cancelLabel": "Cancel",
      "fromLabel": "From",
      "toLabel": "To",
      "customRangeLabel": "Custom",
      "weekLabel": "W",
      "daysOfWeek": [
        "Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"
      ],
      "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "firstDay": 0
    },
    "alwaysShowCalendars": true,
    "opens": "right"
  }, function (start, end, label) {
    //console.log("New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')");
  });

  $('.bootstrap-daterangepicker').each(function (index) {

    if($(this).attr('data-start') && $(this).attr('data-end')){
      $(this).data('daterangepicker').setStartDate($(this).attr('data-start'));
      $(this).data('daterangepicker').setEndDate($(this).attr('data-end'));
    }

    if($(this).attr('data-range') && typeof $(this).data('daterangepicker').ranges[$(this).attr('data-range')] != 'undefined'){
      var moments = $(this).data('daterangepicker').ranges[$(this).attr('data-range')];
      $(this).data('daterangepicker').setStartDate(moments[0])
      $(this).data('daterangepicker').setEndDate(moments[1])
    }
  });

  $('.bootstrap-daXXXXXXXterangepicker').each(function (index) {

    if($(this).attr('data-start') && $(this).attr('data-end')){
      $(this).data('daterangepicker').setStartDate($(this).attr('data-start'));
      $(this).data('daterangepicker').setEndDate($(this).attr('data-end'));
    }

    if($(this).attr('data-range') && typeof $(this).data('daterangepicker').ranges[$(this).attr('data-range')] != 'undefined'){
      var moments = $(this).data('daterangepicker').ranges[$(this).attr('data-range')];
      $(this).data('daterangepicker').setStartDate(moments[0])
      $(this).data('daterangepicker').setEndDate(moments[1])
    }

    if($(this).attr('data-timezone-route')){
      var that = this;
      var timezone_field_id = $(this).attr('id') + '_timezone_id';
      var timezone_field_name = $(this).attr('name') + '_timezone_id';

      $(that).data('daterangepicker').container.append();

      var jqxhr = $.get($(this).attr('data-timezone-route'),
        function (response) {

          if($(that).attr('data-timezone-default')){
            var timezone = $(that).attr('data-timezone-default');
            var onChange = "$('#" + timezone_field_id + "').val($(this).val());";
          }else{
            var timezone = uiSettings.get('timezone');
            var onChange = "$('#" + timezone_field_id + "').val($(this).val()); uiSettings.set('timezone', $(this).val())";
          }

          var menu_html = '<div class="row"><div class="col-sm-6 pull-left" style="padding-left: 20px;"><select data-live-search="true" class="selectpicker" onChange="' + onChange + '">';

          for(var i = 0; i < response.length; i ++){
            menu_html += '<option value="' + response[i].id + '"';
            if(response[i].id == timezone){
              menu_html += ' selected="selected" '
            }
            menu_html += '>' + response[i].label + '</option>';
          }
          menu_html += '</select></div></div>';


          $(that).after('<input type="hidden" id="' + timezone_field_id + '" name="' + timezone_field_name + '" value="' + timezone + '" />');
          $(that).data('daterangepicker').container.append(menu_html);
          $(that).data('daterangepicker').container.find('.selectpicker').selectpicker({
            style: 'btn-default',
            size: 6
          });
        }.bind(that))
        .fail(function () {
          console.log('failed to load timezones');
        });
    }

  });

  /* https://fullcalendar.io */
  // $('#calendar').fullCalendar({
  //   header: {
  //     left: 'prev',
  //     center: 'title',
  //     right: 'month,agendaWeek,agendaDay,next'
  //   },
  //   'editable': true,
  //   eventSources: calendarEventEndpoints
  // });
  //
  // setTimeout(function () {
  //   $('#calendar').fullCalendar('render');
  // }, 50);


  /* Navigation */
  $('a[href="' + window.location.href.split("#")[0] + '"]').addClass('current');
  $('a[href="' + window.location.href.split("#")[0] + '"]').parents('li.has_sub').addClass('open');

  $(".has_sub > a").click(function (e) {
    e.preventDefault();
    var menu_li = $(this).parent("li");
    var menu_ul = $(this).next("ul");

    if(menu_li.hasClass("open")){
      menu_ul.slideUp(350);
      menu_li.removeClass("open")
    }
    else{
      $("#nav > li > ul").slideUp(350);
      $("#nav > li").removeClass("open");
      menu_ul.slideDown(350);
      menu_li.addClass("open");
    }
  });

  $(".sidebar-dropdown a").on('click', function (e) {
    e.preventDefault();

    if(! $(this).hasClass("open")){
      // open our new menu and add the open class
      $(".sidebar #nav").slideDown(350);
      $(this).addClass("open");
    }

    else{
      $(".sidebar #nav").slideUp(350);
      $(this).removeClass("open");
    }
  });


  /* Scroll to Top */
  $(".totop").hide();

  $(function () {
    $(window).scroll(function () {
      if($(this).scrollTop() > 300){
        $('.totop').fadeIn();
      }
      else{
        $('.totop').fadeOut();
      }
    });

    $('.totop a').click(function (e) {
      e.preventDefault();
      $('body,html').animate({scrollTop: 0}, 500);
    });

  });

  /* Forms */
  $('.colorpicker-component').each(function(){
    var picker = $(this).find('input + .input-group-addon');
    $(this).colorpicker({colorSelectors: colorpicker, component: picker});
  });

//https://vsn4ik.github.io/bootstrap-checkbox/
// $('.bootstrap-checkbox').checkboxpicker();

//http://www.bootstrap-switch.org/documentation-2.html
  $('.bootstrap-switch').bootstrapSwitch({onText: '&nbsp', offText: '&nbsp'});
//$('.bootstrap-radio-group input').bootstrapSwitch();

//selectize
  $('select.with_input').selectize({create: true});

})
;

//modal stacking
// $(document).on('hide.bs.modal', '.modal', function () {
//   console.log('modal hidden');
// });

$(document).on('show.bs.modal', '.modal', function () {
  var zIndex = 1040 + (10 * $('.modal:visible').length);
  var nudge = 25 * $('.modal:visible').length;

  $(this).css('z-index', zIndex);
  $(this).css('margin-top', nudge)
  $(this).css('margin-left', nudge)

  setTimeout(function () {
    $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
  }, 0);
});


$(document).on('loaded.bs.modal', '.modal', function () {
  var el = $(this).find(".auto-click").each(function () {
    $(this).trigger('click')
  });
});

$(document).on('hidden.bs.modal', '.modal', function () {
  var modalData = $(this).data('bs.modal');

  // Destroy modal if has remote source – don't want to destroy modals with static content.
  if(modalData && modalData.options.remote){
    // Destroy component. Next time new component is created and loads fresh content
    $(this).removeData('bs.modal');
    // Also clear loaded content, otherwise it would flash before new one is loaded.
    $(this).find(".modal-body").html('<div class="alert alert-info modal-loading"><p><i class="fa fa-exchange throbbing"> </i></p></div>');
  }
});

//image selector
function setPreview(el, base_and_path, params) {
  var image_preview_div = $(el).closest('.input-group').find('.image_preview');
  var loading_span = $(image_preview_div.find('span'))
  loading_span.show();
  setTimeout(function () {
    loading_span.hide();
  }, 15000);

  if(! params){
    params = '';
  }else{
    params = '?' + $.param(params);
  }

  base_and_path = base_and_path.replace("http:", '');
  base_and_path = base_and_path.replace("https:", '');

  var url = base_and_path + '/' + $(el).val() + params;
  url = 'url("' + url + '")';

  image_preview_div.css('background-image', url);
}

function overrideObjectProperties(obj, override_set) {
  var key;

  if(typeof override_set != 'object'){
    return obj;
  }

  // override existing properties
  for(key in obj){
    if(typeof override_set[key] != 'undefined'){
      obj[key] = override_set[key];
    }
  }

  // append extra properties
  for(key in override_set){
    if(typeof obj[key] == 'undefined'){
      obj[key] = override_set[key];
    }
  }

  return obj;
}

function bootStrapAlert(msg, type, options) {

  options = overrideObjectProperties({
    mute:false
  }, options);



  if(typeof type == 'undefined' || type == 'info'){
    type = 'information';
  }

  if(type == 'danger'){
    type = 'error';
  }

  if(typeof audio[type] !== 'undefined' && !options.mute){
    audio[type].play();
  }

  var defaults = {
    text: msg,
    type: type,
    dismissQueue: true,
    layout: 'topRight',
    theme: 'bootstrapTheme',
    closeWith: ['click'],// ['click', 'button', 'hover', 'backdrop']


    template: '<div class="noty_message"><span class="noty_text"></span><div class="noty_close"></div></div>',
    animation: {
      open: 'animated bounceInRight',//{height: 'toggle'}, // or Animate.css class names like: 'animated bounceInLeft'
      close: 'animated bounceOutRight',//{height: 'toggle'}, // or Animate.css class names like: 'animated bounceOutLeft'

      // no effect when using animate.css
      //easing: 'swing',
      //speed: 100 // opening & closing animation speed
    },

    timeout: '3000', // delay for closing event. Set false for sticky notifications
    force: false, // adds notification to the beginning of queue when set to true
    modal: false,
    maxVisible: 15, // you can set max visible notification for dismissQueue true option,
    killer: false, // for close all notifications before show
    callback: {
      onShow: function () {
      },
      afterShow: function () {
      },
      onClose: function () {
      },
      afterClose: function () {
      },
      onCloseClick: function () {
      },
    }/*,
    buttons: [
      {
        addClass: 'btn btn-default btn-sm pull-right',
        text: 'Ok',
        onClick: function ($noty) {

          // this = button element
          // $noty = $noty element

          $noty.close();
        }
      }
      ]*/
  };

  options = overrideObjectProperties(defaults, options);

  return noty(options);
}

function reDrawForm(form) {
  $(form).find(".bootstrap-switch").bootstrapSwitch();
}

function drawLego(el) {
  fillView(el);

  var chart = new google.visualization.TreeMap(el);

  $.get($(el).data('route'), {cache: false})
    .then(function (response) {


      var data = google.visualization.arrayToDataTable(response);
      google.visualization.events.addListener(chart, 'select', function selectHandler() {
        var selectedItem = chart.getSelection();
        if(selectedItem){
          var value = data.getValue(selectedItem.row, selectedItem.column);
          alert('The user selected ' + value);
        }
      });

      var options = {

        headerColor: '#cccccc',
        minColor: '#ff767b',
        midColor: '#f5f5f5',
        maxColor: '#95ff95',
        headerHeight: 35,
        fontSize: 14,
        fontColor: '#666666',
        showScale: false
      }

      chart.draw(data, options);

    })
    .catch(function (response) {
      console.log(response);
    });

}


function fillView(el) {

  if($(el).hasClass('fillscreen')){
    $(el).height($('.mainbar').height());
  }

  if($(el).hasClass('splitscreen')){
    if(typeof $(el).data('split') !== 'undefined'){
      $(el).height(Math.floor($('.mainbar').height() * ($(el).data('split') / 100)));
    }else{
      $(el).height(Math.floor($('.mainbar').height() / 2));
    }
  }

  if($(el).hasClass('fillparent')){
    $(el).height($(el).parent().height());
  }

}


function drawSankey(el) {
  fillView(el);

  var chart = new google.visualization.Sankey(el);

  $.get($(el).data('route'), {cache: false})
    .then(function (response) {

      var data = new google.visualization.DataTable();
      data.addColumn('string', 'From');
      data.addColumn('string', 'To');
      data.addColumn('number', 'Weight');

      data.addRows(response)

      var options = {
        sankey: {
          node: {
            colors: ['#5eb2d9', '#b46e4c', '#4ac344', '#ff8421', '#ff5d5e', '#d7a443']
          }
        }
      }

      chart.draw(data, options);
    })
    .catch(function (response) {
      console.log(response);
    });

}

function thumbnailPickerChange(el, val) {
  $(el).closest('.row').find('.selected').removeClass('selected')
  $(el).addClass('selected')
  $(el).closest('.row').find('input').val(val);
}

function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

var grid_nav = false;
function activateArrowControls(el) {
  $(el).on('keydown',function (e) {

    var coord = $(el).attr('data-coord').split(',');

    switch(e.keyCode){
      case 17:
        grid_nav = !grid_nav;
        break;

      case 37 ://left
        var targ = (coord[0]*1-1) +','+  coord[1]
        break;

      case 39 ://right
        var targ = (coord[0]*1+1) +','+  coord[1]
        break;

      case 40 ://down
        var targ = coord[0] +','+ (coord[1]*1+1)
        break;

      case 38 ://up
        var targ = coord[0] +','+ (coord[1]*1-1)
        break;

      default:
        break;
    }

    if(typeof targ != 'undefined' && grid_nav){
      $("[data-coord='"+ targ +"']").focus()
    }

  }.bind(el));
}

function deactivateArrowControls(el) {
  $(el).off('keydown');
}

function inc(btn) {
  var el = $(btn).closest('.input-group').find('input');
  var val = $(el).val() * 1;
  el.val(val + 1);
  inputChanged(el);
}

function dec(btn) {
  var el = $(btn).closest('.input-group').find('input');
  var val = $(el).val() * 1;
  if(val == 0){
    return;
  }
  el.val(val - 1);
  inputChanged(el);
}

function clr(btn) {
  var el = $(btn).closest('.input-group').find('input');
  el.val(0);
  inputChanged(el);
}

function inputChanged(el){
  el.trigger('change');

  var event = new Event('input', { bubbles: true });
  el[0].dispatchEvent(event);
}